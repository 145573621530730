body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgba(255, 255, 255, 0.904);
  margin: 0%;
}

/* FRANJA DE PORTADA */
.pfranja {
  height: 10em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 1em;
}

.pfranja:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(5px);
}

.ph1 {
  display: flex;
  font-size: 3em;
  font-weight: 500;
  color: white;
  position: relative;
  margin: 0em;
  text-transform: uppercase;
}

/* Listado Productos */
.productos {
  width: 100%;
  margin: 0em auto 1em auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
}

.eachproduct {
  width: 20em;
  height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1em 1em;
  padding: 1em 2em 1em 2em;
}

.eachproduct img {
  width: 100%;
  height: 13em;
  object-fit: cover;
}

.eachproduct img:hover {
  transform: scale(1.01);
  transition: 0.2s;
  opacity: 0.9;
}

.eachproduct .preP {
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0em 0em 0em 0em !important;
}

.eachproduct p {
  text-align: center;
  font-size: 1.4em;
  font-weight: 500;
  color: #000;
  margin: 0em !important;
}

a {
  text-decoration: none !important;
}

/* TABLETS */
@media only screen and (max-width: 1024px) {

  /* Franja de portada  */
  .pfranja {
    height: 7em;
  }

  .ph1 {
    font-size: 2.3em;
  }

  .productos {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: 0%;

  }

  .eachproduct {
    width: 100%;
    height: 20em;
  }

  .eachproduct p {
    margin: 0.5em 0em !important;
  }

  .each-fotos {
    padding: 1em 0em 1em 0em !important;
  }
}
