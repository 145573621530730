/* ErrorPage.css */

/* Fondo de la página */
.error-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: #760000; /* Fondo rojo principal */
  font-family: 'Arial', sans-serif;
  color: white;
  margin: 0;
}

/* Caja de contenido de la página de error */
.error-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 500px;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro para que resalte el texto */
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  width: 90%; /* Ajuste para pantallas pequeñas */
  box-sizing: border-box;
}

/* Estilo del título */
.error-title {
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 20px;
  display: flex;
  color: #fff;
  text-align: center;
}

/* Estilo del mensaje */
.error-message {
  font-size: 20px;
  margin-bottom: 30px;
}

/* Estilo del enlace */
.error-link {
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  padding: 10px 20px;
  border: 2px solid #ffffff;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.error-link:hover {
  background-color: #570303; /* Color oscuro de fondo */
  color: #fff;
  transform: scale(1.4);
  transform-origin: center; /* Asegura que el scaling sea desde el centro */
  padding: 12px 24px; /* Ajusta el padding para que tenga más espacio */
  transition: transform 3s ease, background-color 0.3s ease; /* Transición más suave */
}


/* Adaptación para pantallas pequeñas (celulares) */
@media (max-width: 768px) {
  .error-title {
    font-size: 50px;
  }

  .error-message {
    font-size: 16px;
  }

  .error-link {
    font-size: 14px;
    padding: 8px 16px;
  }
}
