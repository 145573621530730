body {
    margin: 0%;
    font-family: Arial, Helvetica, sans-serif;
}

h1 {
    display: none;
}

section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Franja */

.pdfranja {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 13em;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover; /* Asegura que toda la imagen sea visible */
  background-position: center center; /* Centra la imagen en ambos ejes */
}

.pdfranja:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(5px);
}

.pdh2 {
    display: flex;
    font-size: 3em;
    font-weight: 500;
    color: white;
    position: relative;
    margin: 0em;
}

/* Productos */

h2 {
    font-size: 3em;
    font-weight: 500;
    color: white;
    position: relative;
    text-transform: uppercase;
}

.section_productos {
    width: 100%;
}

/*Productos*/
.product {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1em auto;
    padding: 1em;
    -webkit-box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.75);
}

.photos {
    width: 100%;
    height: 25em;
    margin-top: 0em;
    align-self: center;
}

.img-photo {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    object-fit: cover;
}

.uk-dotnav>.uk-active>* {
    background: #000;
}

.uk-margin {
    margin: 1.2em 0px !important;
    padding: 0px;
}

.uk-margin a {
    background: rgb(175, 174, 174);
}


/* Destalles */
.details {
    width: 100%;
    margin-left: 4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.decorationline {
    width: 5em;
    border-top: 5px solid red;
    margin-bottom: 0.8250em;
}

.details h2 {
    font-size: 1.5em;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin: 0em;
    margin-bottom: 0.2em;
    text-transform: uppercase;
}

.infoProducto {
    align-items: center;
    color: #5e5e5e;
    font-family: segoe ui, PingFang SC, Arial, Microsoft Yahei, Helvetica Neue, Helvetica, Tahoma, sans-serif;
    font-size: 0.85em;
    line-height: 24px;
    font-style: normal;
    font-weight: 450;
    margin: 0.5em 0em;
}

.infoProducto img {
    width: 1.6em;
    margin-right: 0.5em;
}

.bold {
    white-space: nowrap;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.849);
}

.productdivisor {
    width: 70%;
    margin: 2em 0;
    clear: both;
    border-top: 1px solid rgba(82, 82, 82, 0.658);
}

@media only screen and (max-width: 1086px) {

    /* Img Portada */
    .pdfranja {
        height: 9em;
    }

    .pdh2 {
        font-size: 2em;
        text-align: center;
        margin: 0em;
    }

    /*Productos*/
    .product {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .details {
        margin-left: 0em;
    }

    .details h2 {
        font-size: 1.2em;
    }

    .decorationline {
        width: 100%;
    }

}

@media only screen and (max-width: 420px) {
    h2 {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.9em;
        word-wrap: normal;
        text-align: center;
    }

    .details h2 {
        text-align: left;
    }

    .productcomment {
        max-width: 100%;
        margin-top: 0.5em;
    }


}