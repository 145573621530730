/* NAV */
header {
  box-shadow: -1px -5px 49px -6px rgba(0, 0, 0, 0.75);
}

nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.menu-responsive{
  display: none;
}
.uk-navbar-toggle{
min-height: 75px;
}

.uk-navbar-toggle-icon{
  color: black;
}

.uk-icon>* {
  transform: scale(1.4);
}

header img {
  width: 19em;
}

.aimgnav{
  display: flex;
  justify-content: center;
  align-items: center;
}

.linknavphoto{
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  list-style: none;
  display: flex;
  padding: 0.5em 0em;}

.links a {
  font-size: 1em;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-decoration: none;
  text-transform: uppercase;
  margin: 0em 1em;
  padding: 0.6em;
}


.links a:hover {
  color: rgb(196, 0, 0);
  font-size: 1em;
}


.link-active {
  color:rgb(214, 23, 23);
  border-bottom: 3px #cf0000 solid;
}

@media only screen and (max-width: 840px) {

  nav {
      justify-content: space-around;
      padding: 0em 0em;
  }

  .menu-responsive{
      display: flex;
  }

  nav img {
      width: 20em;
      margin: 0em 1em 0em 1em;
  }


  .links {
   display: none;
  }

}

/* Whatapp button */
.whatsapp-icon {
  position: fixed;
  bottom: 40px;
  right: 60px;
  width: 80px;
  height: 80px;
  background-color: #25d366;
  /* Verde de WhatsApp */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: background-color 0.3s;
}

.whatsapp-icon img {
  width: 45px;
  /* Ajusta el tamaño del logo */
  height: 45px;
}

.whatsapp-icon:hover {
  background-color: #128c7e;
  /* Color más oscuro al pasar el mouse */
}


/* Media query para pantallas pequeñas */
@media (max-width: 768px) {

  nav{
    height: 3.5em;
  }

  nav img {
    width: 15em;
    margin: 0em 1em 0em 1em;
}

.links {
    display: none;
}

/* Whatapp button */
.whatsapp-icon {
  bottom: 40px;
  right: 30px;
}

}

