/* Comunicate */
.comunicate {
    width: 100% !important;
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
}

.comunicate:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.685);
}

.contacto {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0em 5em;
    position: relative;
}

.columnacontacto {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
}

.columnacontacto a {
    cursor: pointer;
    padding: 2em;
}

.contactdivisor {
    height: 60px;
    border-right: 1px solid white;
}

.contacto a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.columnacontacto.ContactItem {}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 5.5em;
    max-height: 5.5em;
    margin-bottom: 1.2em;
}

.contacto img {
    width: 4em;
    box-sizing: border-box;
}

.contacto h3 {
    width: 100%;
    margin: 0em;
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 2px;
    color: white;
    text-transform: uppercase;
    text-align: center;
}

.contacto p {
    margin-top: 0.9em;
    font-size: 1em;
    text-align: center;
}

#ubicacionform {
    width: 2.5em;
}

.boton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 1em 0em;
}

.callbutton {
    background-color: rgba(255, 255, 255, 0.966);
    color: rgb(51, 51, 51);
    border-radius: 8px;
    font-weight: 600;
    border: none;
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 0.7em 2em;
}

.callbutton:hover {
    background-color: rgba(193, 193, 193, 0.806);
    color: rgba(35, 35, 35, 0.685);
    transition: 0.2s;
}

/* FIN COMUNICATE */


@media only screen and (max-width: 840px) {


    /* COMUNICATE */
    .contacto {
        flex-direction: column;
    }

    .columnacontacto {
        flex-direction: row;
    }

    .columnacontacto {
        flex-wrap: wrap;

    }

    .columnacontacto a {
        padding: 0.9em;
    }

    .contacto h3 {
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 683px) {

    /* COMUNICATE */
    .contacto {
        flex-direction: column;
        margin: 0em 1em;
    }

    .columnacontacto {
        flex-direction: column;
    }


    .columnacontacto {
        flex-wrap: wrap;
    }

    .callbutton {
        padding: 0.5em 2em;
        line-height: 1.4em;
        border-radius: 50px;
    }

}