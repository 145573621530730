/*Footer*/
footer {
  background: #0a0a0ad5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1em;
}

/* Redes Sociales */
.footerlinetwo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerlinetwo h4 {
  display: flex;
  justify-content: left;
  color: white;
  margin-bottom: 0.4em;
}

.networks {
  display: flex;
  flex-direction: row;
}

.eachnetwork {
  fill: white;
  width: 2.2em;
  margin: 0px 10px 0px 0px
}

/* Nosotros */
.footerlinethree {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0em;
}

.footerlinethree a,
p {
  font-size: 0.9em;
  font-weight: 400;
  text-decoration: none;
  color: rgb(187, 187, 187);
  text-decoration: none;
  margin: 0.5em 0em !important;
}

.divisor {
  width: 50%;
  margin: 1em 0;
  clear: both;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.copyrigth {
  font-size: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1em 0em 0em 0em;
  color: rgba(223, 223, 223, 0.653);
}

/* TABLETS */
@media only screen and (max-width: 1024px) {

    /* Footer */
.footerlinetwo{
  justify-content: flex-start;
  align-items: flex-start;
}

.footerlinetwo{
 align-items: center;
}

.footerlinetwo h4 {
  margin-bottom: 0.7em;
}

.networks {
  margin: 0%;
  justify-content: flex-start;
}

.eachnetwork {
  width: 2.2em;
}

.footerlinethree a, .footerlinethree p{
  text-align: center;
 margin: 0.1em 0em !important;
}

.footerlinethree p {
 display: flex;
 flex-direction: column;
 line-height: 1.6em;
 font-size: 1em;
}

.footerlinethree p span, .fotosrealizados{
  display: none;
}

}