body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgba(255, 255, 255, 0.904);
  margin: 0%;
}


/* Img Portada */
.cfranja {
  height: 10em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 1em;
}

.cfranja:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(5px);
}

.ch1 {
  display: flex;
  font-size: 3em;
  font-weight: 500;
  color: white;
  position: relative;
  margin: 0em;
  text-transform: uppercase;
}

/* Trabajos anteriores */

.ch3,
.ch3-2 {
  text-align: left;
  width: 80%;
  color: rgb(0, 0, 0);
  font-size: 2em;
  font-weight: 600;
  margin: 0em auto 0.6em auto;
}

.nosotros {
  display: flex;
  width: 80%;
  margin: auto auto;
}

.nosotros p {
  width: fit-content;
  margin-right: 4em;
}

.nosotros img {
  width: 100%;
  height: 15em;
  object-fit: cover;

}

/* Slider */
.fotosrealizados {
  width: 80%;
  margin: 0em auto;
}

.fotosrealizados ul {
  max-height: 15em;
}

.fotosrealizados li img {
  margin: 0em 2em;
}

.fotosrealizados li img {
  width: 14em;
  height: 16em;
  ;
  object-fit: cover;
}

#foto_realizados_1 {
  object-position: 100% 0%;
}

#foto_realizados_2 {
  object-position: 100% 50%;
}

#foto_realizados_3 {
  object-position: 100% 0%;
}

#foto_realizados_4 {
  object-position: 0% 0%;
}

#foto_realizados_5 {
  object-position: 0% 0%;
}

#foto_realizados_6 {
  object-position: 100% 0%;
}

#foto_realizados_7 {
  object-position: 50% 0%;
}

*+.uk-grid-margin,
.uk-grid+.uk-grid,
.uk-grid>.uk-grid-margin {
  margin: 0%;
}

/* Clientes */
.clientes {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
  margin-top: 2em;
}

.logosmarcas {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0em 5%;
}

.logosmarcas img {
  width: 8em;
  margin: 0em 1.2em 2em 1.2em;
}

.logosmarcas img:hover {
  transform: scale(1.09);
  transition: 1s;
  border: 1px white;
}


/* TABLETS */
@media only screen and (max-width: 1024px) {

  /* Franja de portada  */
  .cfranja {
    height: 7em;
  }

  .ch1{
    font-size: 2.3em;
  }

  

  /* Trabajos realizados  */
  .ch3 {
    display: none;
  }

  .nosotros {
    flex-direction: column;
  }

  .nosotros p {
    margin-bottom: 2em;
  }

  .fotosrealizados li img {
    width: 50%;
    height: 100%;

  }

  /* Confiaron en nosotros  */
  .clientes {
    margin: 0%;
  }

  .logosmarcas img {
    width: 8em;
    margin: 0.5em 0.5em;
  }

  .ch3-2 {
    font-size: 1.7em;
  }

}

/* CELULARES  */
@media only screen and (max-width: 420px) {
  .titulomarcas {
    display: none;
  }

  .fotosrealizados {
    display: none;
  }
}