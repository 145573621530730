body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0%;
}

select {
  position: fixed;
}

h1 {
  display: none;
}

/* Franja de portada  */
.Ifranja {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13em;
  width: 100%;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 0em;
}

.Ifranja:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.672);
}

.ih2{
  display: flex;
  font-size: 3em;
  font-weight: 500;
  color: white;
  position: relative;
  margin: 0em;
  text-transform: uppercase;
}

/* Tarjetas */

.trabajoslinea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  padding: 0em 2em 2em 2em;
}

.cardsProduct {
  margin: 1em 1em;
  padding: 2em 1EM;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .08)
}

.trabajosuno {
  width: 21em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-self: start;
  margin: 0em 1em;
}

.realizadosfotos {
  margin-bottom: 1.9em;
  position: relative;
}

.realizadosfotos h3 {
  font-size: 1em;
}

.uk-overlay {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
  color: white;

}

.uk-position-bottom {
  bottom: -16px;
  left: 0;
  right: 0;
}

.vermas {
  width: 100%;
  background: rgb(46, 46, 46);
  border: none;
  border-radius: 8px;
  padding: 0.9em 0em;
  text-transform: uppercase;
  font-size: 0.9em;
  cursor: pointer;
  box-sizing: border-box;
  color: white;
  margin: 0.7em 0em;
}

.vermas:hover {
  background: rgb(66, 66, 66);
  transition: 0.2s;
  transform: scale(1.04);

}

.vermas:focus {
  outline: none;
}

.trabajosuno p {
  color: black;
}

strong {
  font-weight: 400;
}

.trabajosuno img {
  height: 15em;
  object-fit: cover;
}

.shawdow {
  width: 90%;
  height: 0.01em;
  background: #000;
  margin: 1.7em 0em 1em 0em;
}

.trabajosuno h3 {
  font-size: 1.5em;
  text-align: center;
  color: #000;
  font-weight: 600;
  margin: 0em 0em;
}

.trabajosuno p {
  width: 100%;
  text-align: center;
  margin: 1em 0em;
}

a {
  text-decoration: none !important;
}

/* Trabajos Realizados */
.realizados {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(70, 70, 70);
  padding: 1em 0em 3em 0em;
}

.realizados h3 {
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 600;
  margin: 0.5em 0em;
}

#filafotos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0em 8em 0em 8em;
}

#foto1,
#foto2,
#foto3,
#foto4,
#foto5,
#foto6,
#foto7,
#foto8,
#foto9 {
  width: 25em;
  height: 20em;
  margin: 1em 1em;
  object-fit: cover;
}

#filafotos img:hover {
  transform: scale(1.08);
  transition: 1s;
  border: 1px white solid;
}

#foto1 {
  object-position: 0% 0%;
}

#foto2 {
  object-position: 100% 100%;
}

#foto3 {
  object-position: 22% 100%;
}

#foto4 {
  object-position: 0% 0%;
}

#foto5 {
  object-position: 100% 00%;
  background: #000;
}

#foto6 {
  object-position: 0% 0%;
  background: #000;
}

#foto7 {
  object-position: 100% 0%;
}

#foto8 {
  object-position: 100% 00%;
  background: #000;
}

#foto9 {
  object-position: 40% 100%;
  background: #000;
}

.uk-lightbox-toolbar {
  margin-bottom: 0.7em;
  font-weight: 500;
  font-size: 1.4em;
  background: rgba(0, 0, 0, 0.561);
}


/* Comunicate */
.comunicate {
  /* background-image: url('');*/
}

.comunicate:before {
  background-color: rgba(0, 0, 0, 0.685);
}

/* TABLETS */
@media only screen and (max-width: 1024px) {

  /* Franja de portada  */
.Ifranja {
  height: 7em;
}

.ih2{
  font-size: 2.3em;
  text-align: center;
}

  /* Tarjetas */
  .cardsProduct {
    margin: 0em 1em;
  }

  .trabajoslinea {
  }

  .vermas {
    border-radius: 20px 20px;
    padding: 0.65em 5em;

  }

  .vermas a {
    width: 100%;
    font-size: 1.1em;
    padding: 0.2em 1em;
  }

  /* Trabajos Realizados */
  .trabajoslinea {
    padding: 1em;
    margin: 0em 0em;
  }

  .realizados h3 {
    text-align: center;
    font-size: 1.5em;
    margin: 0em;
  }
  
  .trabajosuno {
    width: 18em;
    margin: 1em 1em;
  }

  /* FOTOS CLIENTES */
  #filafotos {
    display: grid;
    grid-template-columns: auto auto;
    margin: 0em 0em;

  }

  #filafotos img:hover {
    transform: scale(1);
  }

  *+.uk-grid-margin,
  .uk-grid+.uk-grid,
  .uk-grid>.uk-grid-margin {
    margin: 0%;
    padding: 0em;
  }

  .each-fotos {
    width: 100% !important;
    margin: 0%;
    padding: 0em;
  }

  #foto1,
  #foto2,
  #foto3,
  #foto4,
  #foto5,
  #foto6,
  #foto7,
  #foto8,
  #foto9 {
    width: 90%;
  }


}

/* CELULARES */
@media only screen and (max-width: 420px) {

  /* Franja */
  .franja {
    height: 7em;
    align-items: center;
    justify-content: center;
  }

  /* Trabajos Realizados */
  .trabajoslinea {

    padding: 1em;
    margin-top: 1em;
  }

  .trabajosuno {
    width: 18em;
    margin: 0.3em 1em;
  }


  /*  Fotos clientes */
  .realizados {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 1em 0em 0em 0em;
  }

  #filafotos {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0%;

  }

  .each-fotos {
    margin: 0%;
    padding: 0em;
    width: 100%;
  }

  #foto1,
  #foto2,
  #foto3,
  #foto4,
  #foto5,
  #foto6,
  #foto7,
  #foto8,
  #foto9 {
    width: 100%;
    margin: 1em 0em;
  }

.uk-active{
  /* No pode llevar la imagen del slider de imagenes al centro */
  /* top: 11em !important; */
}

}