body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgba(255, 255, 255, 0.904);
  margin: 0%;
}

h1{
  display: none;
}

section {
  position: relative;
}

/* Comunicate */
.comunicate {
 /* background-image: url('');*/
}

.comunicate:before {
  background-color: rgba(0, 0, 0, 0.685);
}


@media only screen and (min-width: 768px) {}


@media only screen and (min-width: 1024px) {}